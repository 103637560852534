import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, Row } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import LoginOrRegister from "../../../login-or-register/LoginOrRegister";
import CommonMarkUpComponent from "../../../misc-components/common-mark-up-component";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import InterestedIn from "../../../misc-components/InterestedIn";
import PackageLink from "../service/PackageLink";
import Service from "../service/Service";
import DiagonalPackageMarks from "./DiagonalPackageMarks";
import { PackageService } from "./PackageService";

const PackagesAndPricesView = (props) => {
  const ENTITY = "packages-page?populate[0]=meta&populate[1]=packages.services";
  const [error, setError] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packageTitles, setPackageTitles] = useState([]);
  const [yearlySubscription, setYearlySubscrition] = useState(false);
  const [content, setContent] = useState(null);
  const [meta, setMeta] = useState(null)

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEffect(() => {
    if (content) {
      return;
    }
    function addPackageTitle(title) {
      let titles = packageTitles;
      titles.push(title);
      setPackageTitles(titles);
    }

    const stored = LocalStorageService.find("packages_detailed");
    
    if (stored?.length) {
      setPackages(stored);
      setContent(stored.attributes);
      setMeta(stored.attributes.meta);
      stored.attributes.data.packages.forEach((p) => {
        addPackageTitle(p.attributes.title);
      });

      return;
    }

    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data.attributes.packages.data = data.data.attributes.packages.data.sort(
          (a, b) => (a.attributes.weight < b.attributes.weight ? -1 : 1)
        );
        setPackages(data.data.attributes.packages.data);
        setContent(data.data.attributes);
        setMeta(data.data.attributes.meta.data.attributes);

        data.data.attributes.packages.data.forEach((d) => {
          addPackageTitle(d.attributes.title);
        });

        LocalStorageService.store("packages_detailed", data.data);
      })
      .catch((error) => setError(error));
  }, [packages, packageTitles, content, meta]);

  if (!content) {
    return GenericNotFound({
      error: error,
      content: packages?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  function findServiceList(item, index) {
    let subContainer = [];
    if (!item.attributes.services) {
      return subContainer;
    }
    item.attributes.services.data.forEach((subItem, subIndex) => {
      let iconSize = index + 1;
      if (subIndex < 6) {
        subContainer.push(
          <Service
            key={item.attributes.title + subIndex}
            service={subItem}
            isPayedPackage={index > 0}
            firstService={subIndex === 0}
            color={PackageService.findCardBgClassName(item.attributes.title)}
            payedIconSize={iconSize}
            ismobile={props.ismobile}
            boldTitle={true}
            translator={props.translator}
          ></Service>
        );
      }
    });
    subContainer.push(<PackageLink key={index * 3 + item.attributes.title} package={item} translator={props.translator}></PackageLink>);
    return subContainer;
  }

  return (
    <Row className="my-3">
      <BasicMetaData
        title={meta?.title ? meta.title : ''}
        content={meta?.content ? meta.content : ''}
        link={Environment.LANGUAGE_CODE === 'HU' ? 'csomagok' : 'paketi'}
      />
      <div className="row justify-content-center">
        <CommonMarkUpComponent {...content}></CommonMarkUpComponent>
        <Col lg="3" md="6" sm="12" xs="12" className="d-flex flex-column justify-content-around align-items-center">
          <FontAwesomeIcon
            className="package-text-color-basic"
            icon={solid("cube")}
            size="10x"/>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center mt-3">
          <div className="d-flex">
            <div className="m-2">
              <Button
                className={
                  !yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(false)}>
                { props.translator.t('HAVI_ELOFIZETES') }{" "}
              </Button>
            </div>
            <div className="m-2">
              <Button
                className={
                  yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(true)}>
                { props.translator.t('EVES_ELOFIZETES') }{" "}
              </Button>
            </div>
          </div>
        </Col>
      </div>
      <Row className="justify-content-between my-5 py-5">
        {(() => {
          let container = [];
          if (!packages?.length) {
            return container;
          }
          packages.forEach((item, index) => {
            container.push(
              <Col lg="2" md="4" sm="12" xs="12" key={index}>
                <Card className="mt-1">
                  <CardBody style={{ height: "18rem" }} className="with-bg">
                    <CardTitle
                      className={
                        "text-light p-2 text-center rounded card-bg-detailed-" +
                        PackageService.findCardBgClassName(
                          item.attributes.title
                        )
                      }
                      tag="h5">
                      {props.translator.t(item.attributes.title?.replace(" ", "_"))}
                    </CardTitle>
                    <DiagonalPackageMarks
                      index={index}
                      key={index + item.attributes.title}
                      packageTitles={packageTitles}
                      findCardBgClassName={PackageService.findCardBgClassName}
                    ></DiagonalPackageMarks>
                    <CardSubtitle
                      className="mt-1 p-1 bg-white text-center"
                      style={{ height: "5rem" }}>
                      {item.attributes.description}
                    </CardSubtitle>
                    <div className="mt-1 text-center">
                    {PackageService.createPriceContainer(
                      index,
                      item,
                      yearlySubscription,
                      PackageService.findCardBgClassName(item.attributes.title)
                    )}
                    </div>
                  </CardBody>
                  <ListGroup flush className="hoverable-item packages-and-prices-service-container">
                    {findServiceList(item, index)}
                  </ListGroup>
                </Card>
              </Col>
            );
          });
          return container;
        })()}
      </Row>
      <Row className="justify-content-around">
        <Col lg="3" md="4" sm="12" xs="12">
          <LoginOrRegister translator={props.translator}></LoginOrRegister>
        </Col>
        <Col lg="3" md="4" sm="12" xs="12">
          <InterestedIn translator={props.translator}></InterestedIn>
        </Col>
      </Row>
    </Row>
  );
};

export default PackagesAndPricesView;
