import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, NavLink as RRNavLink } from "react-router-dom";
import { Environment } from "../../../core/env/environment";

const LinkToSelectedPackage = (props) => {
  const packageLink =
    props.translator.findLinkTranslation('CSOMAGOK_LINK') + "/" + (Environment.LANGUAGE_CODE === "RS" && props.package.attributes.title === "FREE" ? "starter" :
    props.package.attributes.title.replace(" ", "-").toLowerCase());

  return (
    <div className="d-flex text-dark row text-center mt-2">
      <NavLink
        activeclassname="active"
        to={packageLink}
        tag={RRNavLink}
        className="package-link"
      >
        <strong>
          {props.translator.t('RESZLETEK')}{' '}</strong>
        <span>
          {" "}
          <FontAwesomeIcon
            icon={solid("right-to-bracket")}
          />{" "}
        </span>
      </NavLink>
    </div>
  );
}

export default LinkToSelectedPackage;
