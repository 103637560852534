import { marked } from "marked";
import { Col, Row } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import GenericNotFound from "../../../misc-components/GenericNotFound";

const MainContent = (props) => {
  if (props.error || !props.content || !props.contentTwo || !props.contentThree) {
    return GenericNotFound({
      error: props.error,
      content:
      props.content?.length > 0 && props.contentTwo?.length > 0 && props.content?.length > 0
          ? true
          : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  return (
    <div>
      <Row className="align-middle">
        <Col
          lg={{ size: 4, offset: 4 }}
          md={{ size: 4, offset: 4 }}
          sm="12"
          xs="12"
          className="d-flex row justify-content-around"
        >
          <img
            alt="logo"
            src={Environment.findLogoUrl()}
            style={{
              height: 300,
              width: "auto",
              overflowY: "hidden",
            }}
            className="mt-2"
          />
          <h2 className="main-content-header-color text-center">{props.translator.t('MICRO_ERP')}</h2>
        </Col>
      </Row>
      <Row className="align-middle">
        <Col lg="12" md="12" sm="12" xs="12">
          <h2 className="main-content-header-color my-3 text-center">
            {props.translator.t('MIT_KAP_CSEREBE')} ?{" "}
            <small className="text-secondary">{props.translator.t('MIT_KAP_CSEREBE_DESCRIPTION')}</small>
          </h2>
        </Col>
      </Row>
      <Row className="mt-2 pt-2 justify-content-around">
        <Col lg="3" md="4" sm="12" xs="12" className="p-3 align-items-centered">
          <div
            className="main-content-wrapper"
            dangerouslySetInnerHTML={{ __html: marked.parse(props.contentThree.body) }}></div>
          <div>
            <h5 className="text-secondary">{props.contentThree.subTitle}</h5>
          </div>
        </Col>

        <Col lg="3" md="4" sm="12" xs="12" className="p-3">
          <div
            className="main-content-wrapper"
            dangerouslySetInnerHTML={{ __html: marked.parse(props.contentTwo.body) }}
          ></div>
          <div>
            <h5 className="text-secondary">{props.contentTwo.subTitle}</h5>
          </div>
        </Col>

        <Col lg="3" md="4" sm="12" xs="12" className="p-3">
          <div
            className="main-content-wrapper"
            dangerouslySetInnerHTML={{ __html: marked.parse(props.content.body),}}></div>
          <div>
            <h5 className="text-secondary">{props.content.subTitle}</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MainContent;
