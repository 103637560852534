import axios from "axios";
import { marked } from "marked";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import GenericNotFound from "../../../misc-components/GenericNotFound";


const GeneralTerms = (props) => {
  const ENTITY = "general-terms-and-conditions";
  const [error, setError] = useState();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(content){
      return;
    }
    fetchData();
  }, [content]);

  function fetchData() {
    const stored = LocalStorageService.find(
      "general_terms_and_conditions"
    );
    if (stored?.attributes) {
      setContent(stored.attributes);

      return;
    }
    axios
      .get(Environment.getEntityTypeApiEndpoint(ENTITY), Environment.getApiRequestHeaders())
      .then(({ data }) => {
        setContent(data.data.attributes);
        LocalStorageService.store(
          "general_terms_and_conditions",
          data.data
        );
      })
      .catch((error) => setError(error));
  }

  function getUpdatedAt(updatetAt) {
    return moment(updatetAt).format("DD-MM-YYYY, h:mm a") + " ";
  }

  if (error || !content) {
    return GenericNotFound({
      error: error,
      content: content?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  return (
    <div>
      <Row className="mt-5 pt-5 pb-5 mb-5">
        <BasicMetaData
          title={content.title}
          content={content.title.toLowerCase()}
          link="opsti-uslovi-koriscenja"
        />
        <Col lg="12" md="12" sm="12" xs="12">
          <h1>
            {content.title + " "}{" "}
            <small className="text-muted">{content.subTitle}</small>
          </h1>
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          dangerouslySetInnerHTML={{ __html: marked.parse(content.body) }}
        ></Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <p>{props.translator.t('UTOLSO_MODOSITAS_DATUMA')}: {getUpdatedAt(content.updatedAt)}</p>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralTerms;
