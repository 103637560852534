import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Environment } from "../../core/env/environment";
import { ValidatorPattern } from "../../core/ValidatorPattern";
import BasicMetaData from "../layout/BasicMetaData";

const ContactForm = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [formFields, setFormFields] = useState({
    from: "",
    message: "",
    token: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const isEmailValid = useCallback(() => {
    return ValidatorPattern.EMAIL.test(formFields.from);
  }, [formFields.from]);

  const isMessageValid = useCallback(() => {
    return formFields?.message.length > 0;
  }, [formFields.message]);

  const isFormValid = useCallback(() => {
    return isEmailValid() && isMessageValid();
  }, [isEmailValid, isMessageValid]);

  const handleReCaptchaVerify = useCallback(async () => {
    setIsSubmited(true);

    if ( !isFormValid() ) {
      setError("Postoji greška. Molimo vas da pregledate svoje unose!");
      return;
    }

    if (!executeRecaptcha) {
      setError("Google reCaptcha nedostaje! Pokušajte kasnije!");
      return;
    }

    const token = await executeRecaptcha();
    if (token) {
      let data = formFields;
      data.token = token;

      setIsLoading(true);
      axios
        .post(
          Environment.getApiEndpoint() + "contact-messages",
          { data: { ...data } },
          Environment.getApiRequestHeaders()
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.error) {
            setError(response.data.error.message);
            return;
          }

          setFormFields({
            from: "",
            message: "",
            token: "",
          });
          setSuccess("Poruka je poslata!");
          setIsSubmited(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(
            "Servis trenutno nije dostupan. Molimo Vas, pokušajte kasnije!"
          );
        });
    } else {
      resetForm();
    }
  }, [executeRecaptcha, formFields, isFormValid]);

  function resetForm() {
    setFormFields({
      from: "",
      message: "",
      token: "",
    });

    setError("");
    setSuccess("");
    setIsLoading(false);
    setIsSubmited(false);
  }

  const onFormFieldChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  function createBasicMetaData() {
    return (
      <BasicMetaData
        title="Kontakt"
        content="Kontakt, specifične potrebe,predlozi, ideje, nedostaci, posebni zahtevi i ponude ..."
        link="kontakt"
      />
    );
  }

  function createContactForm() {
    return (
      <Form className="mb-5">
        <FormGroup className="position-relative">
          <Label for="from">{props.translator.t("EMAIL_CIM")} </Label>
          <Input
            name="from"
            value={formFields.from}
            invalid={isSubmited && !isEmailValid()}
            onChange={onFormFieldChange}
            type="email"
          />
          <FormFeedback tooltip>
            {props.translator.t("EMAIL_CIM_NEM_MEGFELELO")}{" "}
          </FormFeedback>
          <FormText>{props.translator.t("EMAIL_CIM_MINTA")} </FormText>
        </FormGroup>
        <FormGroup className="position-relative">
          <Label className="mt-3" for="message">
            {props.translator.t("EMAIL_UZENET")}
          </Label>
          <Input
            name="message"
            value={formFields.message}
            invalid={isSubmited && !isMessageValid()}
            onChange={onFormFieldChange}
            type="textarea"
          />
          <FormFeedback tooltip>
            {props.translator.t("EMAIL_SZOVEGE_HIANYZIK")}{" "}
          </FormFeedback>
          <FormText>{props.translator.t("")} </FormText>
        </FormGroup>
        <Button
          color="success"
          disabled={isLoading}
          onClick={handleReCaptchaVerify}
          className="mt-1 mb-3"
        >
          {props.translator.t("EMAIL_KULDESE")}{" "}
          <FontAwesomeIcon icon={solid("envelope")} />
        </Button>
        <p>
          <em>
            {" "}
            <small>
              {props.translator.t("EMAIL_GOOGLE_RECAPTCHA_1")} <br></br>
              <a href="https://policies.google.com/privacy">
                {props.translator.t("EMAIL_GOOGLE_RECAPTCHA_2")}
              </a>{" "}
              {props.translator.t("ES")}{" "}
              <a href={props.translator.findLinkTranslation("ASZF_LINK")}>
                {props.translator.t("AZ_ALTALANOS_SZERZODESI_FELTETELEKKEL")}
              </a>
              .
            </small>
          </em>
        </p>
      </Form>
    );
  }

  return (
    <Row className="justify-content-center">
      <Col lg="3" md="6" sm="12" xs="12">
        {createBasicMetaData()}
        <Card className="mt-3 bg-light">
          <CardBody>
            <CardTitle tag="h5">
              {props.translator.t("FORDULJON_HOZZANK")}{" "}
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              {props.translator.t("FORDULJON_HOZZANK_DESCRIPTION_1")}{" "}
            </CardSubtitle>
            <CardText>
              {props.translator.t("FORDULJON_HOZZANK_DESCRIPTION_2")}{" "}
            </CardText>
            { success ? <Alert color="success">{success}</Alert> : "" }
            { error ? <Alert color="danger">{error}</Alert> : "" }
            {createContactForm()}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ContactForm;
