import { Environment } from "../../../../core/env/environment";
import { useState } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

const ImageCarousel = (props) => {
  const [componentState, setComponentState] = useState({
    activeIndex: props.currentIndex,
    animating: false,
    images: props.images,
  });

  const previousImageText = props.translator.t('ELOZO');
  const nextImageText = props.translator.t('KOVETKEZO');

  function next() {
    if (componentState.animating) return;
    const nextIndex =
      componentState.activeIndex === componentState.images.length - 1
        ? 0
        : componentState.activeIndex + 1;
    setComponentState({
      ...componentState,
      activeIndex: nextIndex,
    });
  }

  function previous() {
    if (componentState.animating) return;
    const nextIndex =
      componentState.activeIndex === 0
        ? componentState.images.length - 1
        : componentState.activeIndex - 1;
    setComponentState({
      ...componentState,
      activeIndex: nextIndex,
    });
  }

  function goToIndex(newIndex) {
    if (componentState.animating) return;
    setComponentState({
      ...componentState,
      activeIndex: newIndex,
    });
  }

  function setAnimating(value) {
    setComponentState({
      ...componentState,
      animating: value,
    });
  }

  function createSlides() {
    const slides = componentState.images.map((image) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={image.id}
        >
          <div className="d-flex justify-content-center">
            <img
              src={
                Environment.getPublicEndpoint() + image.large.url.substring(1)
              }
              alt={image.title}
              className="img-fluid"
            />
          </div>
          <CarouselCaption
            captionHeader={image.title + "-" + image.subTitle}
            captionText={image.description}
            className="slider-image-carousel-caption package-text-color-basic rounded p-1"
          ></CarouselCaption>
        </CarouselItem>
      );
    });

    return slides;
  }

  return (
    <Carousel
      activeIndex={componentState.activeIndex}
      next={next}
      previous={previous}
      className="main-image-carousel"
      pause="hover"
    >
      <CarouselIndicators
        items={componentState.images}
        activeIndex={componentState.activeIndex}
        onClickHandler={goToIndex}
      />
      {createSlides()}
      <CarouselControl
        direction="prev"
        directionText={previousImageText}
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText={nextImageText}
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default ImageCarousel;
