import { marked } from "marked";
import { Col } from "reactstrap";


const CommonMarkUpComponent = (props) => {

  return (<Col lg="6" md="6" sm="12" xs="12">
    <h1 className="main-content-header-color">{ props.title }</h1>
    <h3>{props.subTitle}{" "}</h3>
    <div dangerouslySetInnerHTML={{ __html: marked.parse(props.body) }}></div>
  </Col>);

}

export default CommonMarkUpComponent;
