import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, NavLink as RRNavLink } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import { Environment } from "../../../../core/env/environment";

function PackageLink(props) {
  const packageLink =
  props.translator.findLinkTranslation('CSOMAGOK_LINK') + "/" + (Environment.LANGUAGE_CODE === "RS" && props.package.attributes.title === "FREE" ? "starter" :
  props.package.attributes.title.replace(" ", "-").toLowerCase());

  return (
    <ListGroupItem>
      <NavLink
        activeclassname="active"
        to={packageLink}
        tag={RRNavLink}
        className="color-ec-3 package-link"
      >
        <span>
          <FontAwesomeIcon className="text-success" icon={solid("ellipsis")} />{' '}
          <FontAwesomeIcon
            className="text-success"
            icon={solid("right-to-bracket")}
          />
        </span>
      </NavLink>
    </ListGroupItem>
  );
}

export default PackageLink;
