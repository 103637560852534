import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ContactForm from "./components/forms/ContactForm";
import CookieBar from "./components/layout/CookieBar";
import Layout from "./components/layout/Layout";
import GenericNotFound from "./components/misc-components/GenericNotFound";
import AboutUs from "./components/page-components/about-us/AboutUs";
import ExtensionsDetailed from "./components/page-components/content/extension/ExtensionsDetailed";
import Faqs from "./components/page-components/content/faq/Faqs";
import PackagesAndPricesView from "./components/page-components/content/packages/PackagesAndPricesView";
import SelectedPackageView from "./components/page-components/content/packages/SelectedPackageView";
import CookiePolicy from "./components/page-components/docs/cookie-policy/CookiePolicy";
import DataProtection from "./components/page-components/docs/data-protection/DataProtection";
import GeneralTerms from "./components/page-components/docs/general-terms/GeneralTerms";
import GalleryWrapper from "./components/page-components/galleries/main-gallery/GalleryWrapper";
import HomePage from "./components/page-components/home-page/HomePage";
import SoftwareSourceCodeForSell from "./pages/software-source-code-for-sale/Software-source-code-for-sale";
import { Environment } from "./core/env/environment";
import { LocalStorageService } from "./core/storage-service/LocalStorageService";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Translator from "./core/translator/translator-service";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024 ? 1 : 0);
  const [translator] = useState(new Translator());

  if (LocalStorageService.isGoogleAnalyticsEnabled()) {
    ReactGA.initialize(Environment.MEASURMENT_ID);
  }

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 1024)
  }

  const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((icon) => Icons[icon]);

  library.add(...iconList);


  LocalStorageService.clear();

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileDevice = !undefined && isMobile ? 1 : 0;

  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={Environment.GOOGLE_SITE_KEY}>
        <Routes>
          <Route path="/" element={<Layout translator={translator} />}>
            <Route index element={<HomePage ismobile={isMobileDevice} translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('CSOMAGOK_LINK')} element={<PackagesAndPricesView ismobile={isMobileDevice} translator={translator} />} />{" "}
            <Route
              path={translator.findLinkTranslation('INGYENES_CSOMAG_LINK')}
              element={<SelectedPackageView activePackage={{ id: 1 }} ismobile={isMobileDevice} translator={translator} />}
            />{" "}
            <Route
              path={translator.findLinkTranslation('BASIC_CSOMAG_LINK')}
              element={<SelectedPackageView activePackage={{ id: 2 }} ismobile={isMobileDevice} translator={translator} />}
            />{" "}
            <Route
              path={translator.findLinkTranslation('PRO_CSOMAG_LINK')}
              element={<SelectedPackageView activePackage={{ id: 3 }} ismobile={isMobileDevice} translator={translator} />}
            />{" "}
            <Route
              path={translator.findLinkTranslation('BUSINESS_CSOMAG_LINK')}
              element={<SelectedPackageView activePackage={{ id: 4 }} ismobile={isMobileDevice} translator={translator} />}
            />{" "}
            <Route
              path={translator.findLinkTranslation('BUSINESS_PLUS_CSOMAG_LINK')}
              element={<SelectedPackageView activePackage={{ id: 5 }} ismobile={isMobileDevice} translator={translator}/>}
            />{" "}
            <Route path={translator.findLinkTranslation('MODULOK_LINK')} element={<ExtensionsDetailed ismobile={isMobileDevice} translator={translator} />} />
            <Route path={translator.findLinkTranslation('KEPTAR_LINK')} element={<GalleryWrapper ismobile={isMobileDevice} translator={translator}/>} />{" "}
            <Route path={translator.findLinkTranslation('GYIK_LINK')} element={<Faqs ismobile={isMobileDevice} translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('ROLUNK_LINK')} element={<AboutUs ismobile={isMobileDevice} translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('ADATVEDELEM_LINK')} element={<DataProtection translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('SUTIPOLITIKA_LINK')} element={<CookiePolicy translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('ASZF_LINK')} element={<GeneralTerms translator={translator} />} />{" "}
            <Route path={translator.findLinkTranslation('KAPCSOLAT_LINK')} element={<ContactForm ismobile={isMobileDevice} translator={translator}/>} />{" "}
            <Route path="/izvorni-kod-softvera-za-prodaju" element={<SoftwareSourceCodeForSell/>} />{" "}
            <Route
              path="*"
              element={<GenericNotFound invalidPath={true} translator={translator}/>}
            />
          </Route>{" "}
        </Routes>{" "}
      </GoogleReCaptchaProvider>
      <CookieBar></CookieBar>
    </BrowserRouter>
  );
};

export default App;
