import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle
} from "reactstrap";
import { Environment } from "../../core/env/environment";

const LoginOrRegister = (props) => {
  return (
    <div
      className="text-center mt-1"
    >
      <h3 className="main-content-header-color">{ props.translator.t('AZ_ALKALMAZAS_ELERHETO_DEMO_MODBAN_IS') }</h3>
      <Card className="mt-3">
        <CardBody>
          <CardTitle tag="h3" className="main-content-header-color">
          { props.translator.t('REGISZTRACIO') }
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h4">
          { props.translator.t('KOTELEZETTSEGEK_NELKUL') }
          </CardSubtitle>
          <CardText className="lead">
          { props.translator.t('DEMO_STATUSZBAN') }<br></br>{ props.translator.t('MINDEN_SZOLGALTATAS_ELERHETO') }
          </CardText>
          <CardText className="lead">
          { props.translator.t('A_REGISZTRACIOVAL_AUTOMATIKUSAN_INGYENES_CSOMAGOT_KAP') }
          </CardText>
          <CardText className="lead">
          { props.translator.t('AZ_ALKALMAZASBAN_IGENYELHET_MASIK_CSOMAGOT') } 
          </CardText>
          <Button
            className="main-content-bg-color"
            onClick={(event) =>
              (window.location.href =
                Environment.REGISZTRACIO)
            }>
            { props.translator.t('REGISZTRACIO_ES_DEMO') }{" "}
            <FontAwesomeIcon icon={solid("right-to-bracket")} />
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default LoginOrRegister;
