import axios from "axios";
import { useEffect, useState } from "react";
import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import Gallery from "./Gallery";

const GalleryWrapper = (props) => {
  const ENTITY = "images?populate=*";

  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if(!isLoading){
      return;
    }
    fetchData();
  });

  function fetchData() {
    const stored = LocalStorageService.find("gallery_images");
    if (stored && stored.length) {
      setImages(stored);
      setIsLoading(false);

      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data = data.data.sort((a, b) =>
          a.attributes.weight < b.attributes.weight ? -1 : 1
        );
        let imagesLoaded = [];
        data.data.forEach((imageData) => {
          imagesLoaded.push(createImage(imageData));
        });
        setImages(imagesLoaded);
        LocalStorageService.store("gallery_images", imagesLoaded);
        setIsLoading(false);
      })
      .catch((error) => {
        // IGNORED
      });
  }

  function createImage(imageData) {
    return {
      id: imageData.id,
      title: imageData.attributes.title,
      subTitle: imageData.attributes.subtitle,
      description: imageData.attributes.description,
      thumbnail: {
        url: imageData.attributes.picture.data.attributes.formats.thumbnail.url,
      },
      medium: {
        url: imageData.attributes.picture.data.attributes.formats.medium.url,
      },
      large: {
        url: imageData.attributes.picture.data.attributes.formats.large.url,
      },
    };
  }

  return isLoading ? (
    <></>
  ) : (
    <Gallery images={images} imagesToShow={14} limit={24} translator={props.translator} />
  );
};

export default GalleryWrapper;
