import { Environment } from "../env/environment";
import Translations from "./translations";
import LinkTranslations from "./link-translations";

export default class Translator {

  t(translatable, params){
    let translated = Environment.LANGUAGE_CODE === 'HU' ? Translations.HU[translatable] : Translations.RS[translatable];
    if(!translated){
      return '';
    }
    return this.findTranslation(translated, params);
  }

  findLinkTranslation(translatable, params){
    let translated = Environment.LANGUAGE_CODE === 'HU' ? LinkTranslations.HU[translatable] : LinkTranslations.RS[translatable];
    if(!translated){
      return translatable;
    }
    return this.findTranslation(translated, params);
  }

  findTranslation(translated, params){
    let index = 0;
    let arg = '';
    if(params && params.length){
      params.forEach(param => {
        arg = '{'+ index + '}';
        if(translated.indexOf(arg)){
          translated = translated.replace(arg, param);
        }
        index++;
      });
    }

    return translated;
  }

}