import axios from "axios";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Environment } from "../../../core/env/environment";
import { LocalStorageService } from "../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../layout/BasicMetaData";
import GenericNotFound from "../../misc-components/GenericNotFound";

const AboutUs = (props) => {
  const ENTITY = "about-us-page?populate=*";
  const [error, setError] = useState();
  const [content, setContent] = useState(null);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    if (content) {
      return;
    }
    fetchData();
  }, [content]);

  function fetchData() {
    const stored = LocalStorageService.find("about_us_page");
    if (stored?.attributes) {
      setContent(stored.attributes);
      setMeta(stored.attributes.meta.data.attributes);

      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        setContent(data.data.attributes);
        setMeta(data.data.attributes.meta.data.attributes);
        LocalStorageService.store("about_us_page", data.data);
      })
      .catch((error) => setError(error));
  }

  if (error || !content) {
    return GenericNotFound({
      error: error,
      content: content?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator,
    });
  }

  return (
    <Row className="my-3">
      <BasicMetaData
        title={meta.title}
        content={meta.content}
        link={Environment.LANGUAGE_CODE === "HU" ? "rolunk" : "o-nama"}
      />
      {(() => {
        let containerB = [<div key="2"></div>];
        return containerB;
      })()}
      <Col xs="12">
        <h1 className="main-content-header-color">{content.title + " "} </h1>
        <h3 className="main-content-header-color">
          <small className="text-muted">{content.subTitle}</small>
        </h3>
        <div
          className="p-2 rounded main-content-wrapper"
          dangerouslySetInnerHTML={{ __html: marked.parse(content.body) }}
        ></div>
      </Col>
    </Row>
  );
};

export default AboutUs;
