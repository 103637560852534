import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { marked } from "marked";
import { useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";

function ServiceDetail(props) {
  const ENTITY = "service";
  const BODY_IS_NOT_SET =
    "Trenutno nema podataka o detaljima, pokušajte kasnije!";
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState();
  const [content, setContent] = useState({ ...props.service.attributes });

  const toggle = () => {
    setCanvas(!canvas);
    if (canvas) {
      props.parentCallBack();
      return;
    }

    const stored = LocalStorageService.find("service_" + props.service.id);
    if (stored?.attributes) {
      setContent(stored.attributes);

      return;
    }

    const dataUrlPath = ENTITY + "s/" + props.service.id;
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(dataUrlPath),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        setContent(data.data.attributes);
        LocalStorageService.store("service_" + content.id, data.data);
      })
      .catch((error) => setError(error));
  };

  function findCanvasBody() {
    return canvas && content.body
      ? { __html: marked.parse(content.body) }
      : { __html: marked.parse(BODY_IS_NOT_SET) };
  }

  if (error) {
  } else {
    return (
      <div>
        <span style={{ float: "right" }} className="text-dark" onClick={toggle}>{props.translator.t('RESZLETEK')} ...{' '}<FontAwesomeIcon
          role="button"
          className="p-1 rounded bg-success text-light"
          icon={solid("arrow-up-right-from-square")}
        /></span>
        <Offcanvas isOpen={canvas} toggle={toggle}>
          <OffcanvasHeader toggle={toggle}>
          {content.title}
          </OffcanvasHeader>
          <OffcanvasBody dangerouslySetInnerHTML={findCanvasBody()}/>
        </Offcanvas>
      </div>
    );
  }
}

export default ServiceDetail;
