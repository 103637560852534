import Translator from "../../../../core/translator/translator-service";
import PriceContainer from "./PriceContainer";


export class PackageService {

  static createPriceContainer = (index, item, yearlySubscription, color) => {
    const translator = new Translator();
    return (<PriceContainer key={item.attributes.id}
       priceMonthly={item.attributes.priceMonthly} 
       priceYearly={item.attributes.priceYearly} 
       currency={item.attributes.currency} 
       yearlySubscription={yearlySubscription} 
       color={'package-text-color-' + color}
       translator={translator}/>);
  }

  static findCardBgClassName(title) {
    title = title.replace(" ", "-");
    return title.toLowerCase();
  }

}